export const planBenefits = [
  'Assessoria completa',
  'Fechamento contábil',
  'Envio das guias de imposto',
  'Certidões negativas mensais',
  'Descontos exclusivos',
]

export const digitalCertificateBenefits = ['Emissão de certificado tipo e-CPF/E-CNPJ']

export const meiDescription = {
  name: 'MEI',
  description: 'Faturamento anual até R$ 81 mil',
}

export const simplesNacionalDescription = {
  name: 'Simples Nacional',
  description: 'Faturamento anual até R$ 4,8 milhões',
}

export const otherDescription = {
  name: 'Personalizado',
  description: 'Sem limite de faturamento',
}

export const digitalCertificateDescription = {
  name: 'Certificado Digital',
  description: 'Emita seu certificado digital',
}
